<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <ListingComponent ref="UsersList"
                          class="bg-white"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
        <b-modal v-model="isBlockModal"
                 :no-close-on-backdrop="true"
                 centered title="Block modal"
                 @cancel="closeBlockModal"
                 @ok="blockUser">
            <form>
                <h3 v-if="isBlock" class="text-black-50">Do you really want to block this member</h3>
                <h3 v-else class="text-black-50">Do you really want to unblock this member</h3>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import ListingComponent from '@/components/listing/ListingComponent'

    export default {
        name: "UsersListPage",
        data () {
            return {
                id: null,
                isBlockModal: false,
                isBlock:null,
                columnConfig: [
                    {
                        columnCode: 'action',
                        columnName: 'Action',
                        value: (item) => {
                            let blockLink
                            if (item && item.userBlockedByAdmin ===true) {
                                blockLink = <b-dropdown-item onClick={() => this.showBlockModal(item,false)}>Unblock</b-dropdown-item>
                            }else {
                                blockLink = <b-dropdown-item onClick={() => this.showBlockModal(item,true)}>Block</b-dropdown-item>
                            }
                            return (
                                <b-dropdown variant="link" class="listing-action">
                                {blockLink}
                            </b-dropdown>
                        )
                        }
                    },
                    {
                        columnCode: 'CHAT_ID',
                        columnName: 'Chat Id',
                        align: 'left',
                        value: item => {
                            return item.chatId
                        }
                    },
                    {
                        columnCode: 'FIRST_NAME',
                        columnName: 'First Name',
                        align: 'left',
                        value: item => {
                            return item.firstName
                        }
                    },
                    {
                        columnCode: 'LAST_NAME',
                        columnName: 'Last Name',
                        align: 'left',
                        value: item => {
                            return item.lastName
                        }
                    },
                    {
                        columnCode: 'PHONE',
                        columnName: 'Phone',
                        align: 'left',
                        value: item => {
                            return item.phoneNumber
                        }
                    },
                    {
                        columnCode: 'USERNAME',
                        columnName: 'User Name',
                        align: 'left',
                        value: item => {
                            return item.userName
                        }
                    },
                    {
                        columnCode: 'STATUS',
                        columnName: 'Status',
                        align: 'left',
                        value: item => {
                            return item.userBlockedByAdmin ? 'Blocked' : 'Active';
                        }
                    }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('telegramUsers/list', payload)
            },
            closeBlockModal () {
                this.id = null
                this.$nextTick(() => {
                    this.isBlockModal = false
                })
            },
            showBlockModal (item, isBlock) {
                if (!item.id) {
                    this.$toastr.error('Users not found')
                }
                this.id = item.id
                this.isBlockModal = true
                this.isBlock = isBlock
            },
            blockUser () {
                if (!this.id) {
                    return
                }
                var ss = this.isBlock ? 'block' : 'unblock'
                this.axios.delete('telegramUsers/' + ss, {params: {id: this.id}} )
                    .then((result) => {
                        this.$refs.UsersList.doRequest()
                        if (result.data && result.data.status === 'success') {
                            this.$toastr.success('User successfully blocked')
                        }
                        this.isBlockModal = false
                    })
            },
            addNewButton () {
                return null
            }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>